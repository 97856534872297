.chevron-image {
    margin-bottom: 1.5rem;
    }

@media (width >= 1200px) {
    .chevron-image {
        max-width: none;
        margin-left: -3.8%;
        width: 103.8%;

        &.chevron-image--right {
            margin-left: 0;

            margin-right: -3.8%;
            }
        }

    .image-title-space {
        margin-top: 3.5rem;
        }
}

