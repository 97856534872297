/** ------------------------------
    Box sizing
    ------------------------------ */
html {
    box-sizing: border-box;
    }

*,
*::before,
*::after {
    box-sizing: inherit;
    }

/** ------------------------------
    Sections
    ------------------------------ */

.section {
    margin: var(--section-margin);
    overflow: hidden;
    }



@media (width < 768px) {
    .section {
        margin: 3rem auto;
        }
    .site-header + .section {
        margin-top: 1.5rem;
        }
}

/** ------------------------------
    Containers
    ------------------------------ */
@define-mixin container {
    margin: auto;
    max-width: var(--container-width);
}

@define-mixin container-gutters {
    padding: 0 calc(var(--container-gutter) / 2);
}

.container {
    @mixin container;
    @mixin container-gutters;
    }

.container--md {
    max-width: calc(var(--container-width) * .8);
    }

.container--sm {
    max-width: calc(var(--container-width) * .6);
    }

.container--fluid {
    max-width: none;
    }

.is-contained {
    @mixin container;
    }


/** ------------------------------
    Skip link
    ------------------------------ */
.skip-link {
    @mixin visually-hidden;
    background-color: #000;
    color: #fff;
    text-decoration: none;

    &:focus {
        padding: 1em;
        margin: 0;
        width: auto;
        height: auto;
        clip: auto;
        }
    }
