/** ------------------------------
    Site Footer
    ------------------------------ */
.site-footer {
    margin-top: 3rem;
    padding: 3rem 0;
    background-color: var(--brand-secondary);
    color: #fff;
    }

    /* Grid */
    .site-footer__grid-item {
        margin: 1rem 0;

        &:first-child {
            margin-top: 0;
            }
        }

@media (width >= 600px) {

    .site-footer__grid {
        @mixin grid-container;
        display: flex;
        flex-wrap: wrap;
        }
        .site-footer__grid-item {
            @mixin grid-item;
            flex: 1 0 50%;
            margin: 0;

            &:first-child {
                flex-basis: 100%;
                }
            }

}

@media (width >= 900px) {

    .site-footer__grid-item {
        flex-basis: 33.333% !important;
        }

}

