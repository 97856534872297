/** ------------------------------
    Base
    ------------------------------ */
html {
    font: var(--base-font-weight) var(--base-font-size)/var(--base-line-height) var(--base-font-family);
    color: var(--base-color);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    }

::selection {
    background-color: red;
    color: white;
    }


/** ------------------------------
    Links
    ------------------------------ */
a {
    color: currentcolor;
    text-decoration: underline;
    text-decoration-skip-ink: auto;

    &:hover {
        color: #000;
        }
    }

.click-icon {
    height: 2.5rem;
    position: relative;
    top: -5px;
    }


/** ------------------------------
    Headings
    ------------------------------ */
h1, h2, h3, h4, h5, h6 {
    @mixin base-margin;
    font-family: var(--heading-font-family);
    font-weight: var(--heading-font-weight);
    line-height: 1.1;
    color: #000;
    }


.hd-xl {
    font-size: 4rem;
    }

.hd-lg {
    font-size: 3rem;
    }

.hd-md {
    font-size: 1.5rem;
    }

.hd-bd {
    font-size: 1rem;
    }

.hd-sm {
    font-size: .85rem;
    }

.hd-xs {
    font-size: .75rem;
    }

.hd-separator {
    margin: 2rem 0;
    padding: .75rem;
    background-color: #000;
    color: #fff;
    font-size: 1.25rem;
    line-height: 1;
    text-transform: uppercase;
    }

.lead {
    font-size: 2rem;
    }

.txt-upper {
    text-transform: uppercase;
    }

.txt-center {
    text-align: center;
    }

.highlight {
    color: var(--brand-primary);
    font-weight: 600;
    }

.styled-list {
    list-style: disc;
    margin: 0 0 1.5rem 0;
    padding: 0 0 0 18px;

    & li + li {
        margin-top: 1rem;
    }
}


/** ------------------------------
    Content
    ------------------------------ */
p {
    @mixin base-margin;
    }

ol,
ul {
    @mixin base-margin;
    }

.txt-click {
    margin-bottom: 0.5rem;
    }

.legal {
    font-size: 0.7rem;
    color: #222;
    margin-bottom: 0.5rem;

    & svg {
        position: relative;
        top: -2px;
        }
    }
