/** ------------------------------
    Icon List
    ------------------------------ */
.icon-list {
    @mixin list-reset;
    display: flex;
    align-items: center;
    }
    .icon-list__item {

        &:not(:first-child) {
            margin-left: .25em;
            }

        }
