/** ------------------------------
    Site Header
    ------------------------------ */
@custom-media --menu-collapsed (width < 1200px);
@custom-media --menu-expanded (width >= 1200px);

.site-header {
    & a {
        text-decoration: none;
        }
    }
    .site-header__brand-link {
        display: inline-block;

        & img {
            height: 85px;
            }
        }

    .site-header__search {
        position: relative;

        & img {
            position: absolute;
            top: 6px;
            right: 11px;
            height: 20px;
            width: auto;
            }

        & input {
            width: 100%;
            border: 1px solid #939598;
            border-radius: 30px;
            padding: 0.5em 0.5rem 0.5rem 0.75rem;
            min-width: 220px;
            }
        }

    /* Toggle */
    .site-header__menu-toggle {
        @mixin button-reset;
        margin-left: auto;
        padding: .5rem;
        width: 2.5rem;
        height: 2.5rem;
        position: absolute;
        top: 14px;
        right: 0.7rem;
        }

@media (--menu-collapsed) {

    .site-header {
        @mixin container-gutters;
        display: block;
        position: relative;
        padding-top: 1rem;
        }
        .site-header__brand-link {
            margin-bottom: 1rem;

            & img {
                height: 35px;
                }
            }
        .site-header__search {
            padding-bottom: 1rem;
            }
        .site-header__menu {
            width: 100%;
            order: 3;

            &:not(.site-header__menu--active) {
                display: none;
                }

            & ul {

                & > li {
                    border-top: 1px solid rgba(0, 0, 0, .1);
                    }

                & a {
                    display: block;
                    padding: .5em 0;
                    color: #000;
                    font-weight: 600;

                    & br {
                        display: none;
                        }

                    & img {
                        display: none;
                        }

                    & i {
                        font-style: normal;
                        }
                    }

                }

            }

}

@media (--menu-expanded) {

    .site-header__top-bar {
        @mixin container;
        @mixin container-gutters;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        }

    .site-header__menu {
        margin-left: auto;
        background: #000;
        overflow-y: hidden;

        & ul {
            display: flex;
            @mixin container;
            @mixin container-gutters;
            justify-content: space-between;

            & li {
                display: flex;
                flex: 2 1 auto;
                align-items: center;
                height: 50px;
                position: relative;
                background: #000;

                &:last-child {

                    & a::before {
                        right: -20px;
                        border-top: 8px solid #fff;
                        border-right: 8px solid #fff;
                        }
                    }

                &:first-child {
                    margin-left: -10px;

                    & a::after {
                        left: -22px;
                        background: #000;
                        }
                    }

                &:matches(.current, :hover) a {
                    background: #f40000;

                    &:before {
                        background: #f40000;
                        }
                    }
                }

            & a {
                display: flex;
                align-items: center;
                height: 50px;
                width: 100%;
                color: #fff;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 1.2;
                font-size: 0.8rem;
                padding: 0 10px 0 50px;
                justify-content: center;

                & img {
                    width: 36px;
                    max-width: none;
                    }

                & i {
                    display: none;
                    }

                & span {
                    z-index: 5;
                    }

                &::after {
                    content: "";
                    display: block;
                    width: 50px;
                    height: 50px;
                    border-top: 8px solid #fff;
                    border-right: 8px solid #fff;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 0;
                    left: -26px;
                    }

                &::before {
                    content: "";
                    display: block;
                    width: 50px;
                    height: 50px;
                    border-top: 8px solid transparent;
                    border-right: 8px solid transparent;
                    transform: rotate(45deg);
                    position: absolute;
                    top: 0;
                    right: -13px;
                    background: #000;
                    z-index: 2;
                    }
                }
            }

        }

    /* Toggle */
    .site-header__menu-toggle {
        display: none;
        }

}
