/** ------------------------------
    Social List
    ------------------------------ */
.social-list {
    @mixin list-reset;
    display: flex;
    align-items: center;
    }
    .social-list__item {

        &:not(:first-child) {
            margin-left: .5em;
            }

        & :matches(a, svg) {
            display: block;
            width: 24px;
            height: 24px;
            }

        }
