/** ------------------------------
    Filters
    ------------------------------ */
@define-mixin filter-control {
    margin: 2px;
    padding: .61em;
    width: calc(50% - 4px);
    background: red;
    color: #fff;
    font-size: .875rem;
    line-height: 1.1;
    text-align: center;
    text-transform: uppercase;

    @media (width >= 640px) {
        width: calc(33.333% - 4px);
        }

    @media (width >= 800px) {
        width: calc(25% - 4px);
        }

    @media (width >= 960px) {
        width: calc(20% - 4px);
        }
}

.documents__filter-list {
    display: flex;
    flex-wrap: wrap;
    margin: -2px -2px 1.5rrem;
    }
    .documents__filter-item {
        @mixin filter-control;

        &.is-active {
            background-color: black;
            }
        }

.documents__filter-actions {
    text-align: right;

    & > * {
        @mixin btn-reset;
        @mixin filter-control;
        }
    }

.documents__categories {
    }
    .documents__categories-title {
        text-transform: uppercase;
        }
    .documents__document-title {
        margin-top: 1rem;
        margin-bottom: .5em;
        font-weight: 500;
        text-transform: uppercase;
        }

@media (width >= 680px) {

    .documents__categories {
        columns: 2;
        }
        .documents__categories-item {
            break-inside: avoid;
            }

}

/** ------------------------------
    Brandings
    ------------------------------ */
@define-mixin brand-filters $hex {

    & :matches(.documents__filter-item, .documents__filter-clear) {
        background-color: $hex;

        &.is-active {
            background-color: black;
            }
        }

}

.brand-vision {
    @mixin brand-filters #ff550e;
    }

.brand-insights {
    @mixin brand-filters #e4813d;
    }

.brand-strategy {
    @mixin brand-filters #ff9900;
    }

.brand-international {
    @mixin brand-filters #d6b85b;
    }

.brand-digital {
    @mixin brand-filters #ecb04f;
    }

.brand-sustainability {
    @mixin brand-filters #b59e74;
    }

.brand-toolkits {
    @mixin brand-filters #6ace7e;
    }

.brand-best {
    @mixin brand-filters #6ac8ce;
    }
