/** ------------------------------
    Fonts
    ------------------------------ */


@font-face {
    font-family: 'Unity';
    src: url('/assets/fonts/TCCC-UnityHeadline-Bold.woff2') format('woff2'),
         url('/assets/fonts/TCCC-UnityHeadline-Bold.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'Unity';
    src: url('/assets/fonts/TCCC-UnityHeadline-Medium.woff2') format('woff2'),
         url('/assets/fonts/TCCC-UnityHeadline-Medium.woff') format('woff');
    font-weight: 400;
}


