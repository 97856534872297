.toolkits-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1rem;
    margin-right: -1rem;
    }
    .toolkits__card {
        width: 50%;
        padding: 1rem;

        & h2 {
            margin-bottom: 0.5rem;
            }

        & > img {
            width: 100%;
            max-width: 240px;
            margin-bottom: 1rem;
            }

        & ul {
            list-style: none;
            margin: 0;
            padding: 0;

            & p {
                margin-bottom: 0;
                font-size: 15px;
                text-transform: uppercase;
                color: #000;
                line-height: 1.35;
                }
            & img {
                width: 35px;
                margin-bottom: 0.5rem;
                }
            }
        }

@media (width >= 768px) {
    .toolkits__card {
        width: 33.3333%;
        }
    }

@media (width < 768px) {
    .toolkits__card {
        & ul {
            & p {
                font-size: 0.7rem;
                }
            }
        }
    }
