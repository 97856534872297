/** ------------------------------
    Base
    ------------------------------ */
.btn {
    display: inline-block;
    padding: var(--btn-padding);
    height: var(--btn-height);
    border: 0;
    border-radius: var(--btn-border-radius);
    background-color: var(--btn-default-bg);
    font-family: var(--btn-font-family);
    font-weight: var(--btn-font-weight);
    color: var(--btn-default-color);
    text-align: left;
    text-decoration: none;
    white-space: nowrap;
    vertical-align: middle;
    text-transform: uppercase;
    min-width: 265px;
    display: inline-block;
    font-size: 0.9rem;

    &:focus {
        outline: 0;
        }

    &:hover {
        color: var(--btn-default-color);
        transform: translateY(-1px);
        box-shadow: 0 4px 10px rgb(0 0 0 / 10%);
        }

    & + .btn {
        margin-top: 1rem;
        }
    }
    .btn__icon {
        display: inline-block;
        margin: 0 4px;
        vertical-align: middle;
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.btn--block {
    width: 100%;
    }

.btn--ghost {
    border: 1px solid currentcolor;
    background-color: transparent;
    color: inherit;
    }

.btn--flat {
    border-radius: 0;

    &:hover {
        transform: none;
        box-shadow: none;
        opacity: .8;
        }
    }
