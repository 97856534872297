.quote {
    color: var(--brand-primary);
    font-size: 1.75rem;
    line-height: 1.2;
    font-weight: 600;
    }
    .quote-author {
        font-size: 0.8rem;
        color: #000;
        font-weight: 600;
        text-align: center;
        }

@media (width < 768px) {
    .quote {
        font-size: 1.3rem;
        }
}
