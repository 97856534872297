.icon-links__row {
    display: flex;
    align-items: center;
    justify-content: space-between;


    & +.icon-links__row {
        margin-top: 3rem;
        }
    }
    .icon-links__row {
        & a {
            display: inline-block;
            width: 125px;
            max-width: 100%;
            }
        }
    .icon-links__row--half {
        & a {
            width: 50%;
            display: inline-block;
            text-align: center;

            & img {
                display: block;
                margin: 0 auto;
                width: 100px;
                }
            }
    }

@media (width >= 768px) {
    .large-icon {
        width: 140px !important;
        }
}
