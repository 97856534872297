/** ------------------------------
    Grid
    ------------------------------ */
.grid {
    position: relative;
    margin-left: calc(var(--grid-gutter) / -2);
    margin-right: calc(var(--grid-gutter) / -2);
    }
    .grid__cell {
        float: left;
        width: 100%;
        padding-left: calc(var(--grid-gutter) / 2);
        padding-right: calc(var(--grid-gutter) / 2);
        min-height: 1px;
        }

.grid:not(.grid--flex) {
    clear: fix;
    }

.grid + .grid {
    margin-top: 2.5rem;
    }


/** ------------------------------
    Flex grid
    ------------------------------ */
.grid--flex {
    display: flex;
    flex-wrap: wrap;
    }
.grid--flex.grid--reversed {
    flex-direction: row-reverse;
    }
    .grid--flex > .grid__cell {
        float: none !important;
        }


/** ------------------------------
    Modifiers
    ------------------------------ */
.grid--collapsed {
    margin-left: 0;
    margin-right: 0;
    }
    .grid--collapsed > .grid__cell {
        padding-left: 0;
        padding-right: 0;
        }

.grid--reversed > .grid__cell {
    float: right;
    }


/** ------------------------------
    Responsive breakpoints
    ------------------------------ */
@custom-media --grid-sm (width >= 768px);
@custom-media --grid-md (width >= 1024px);
@custom-media --grid-lg (width >= 1440px);

@define-mixin grid $size, $cols: 12 {

    .grid__cell--$(size)-1 { width: calc(1/$cols * 100%); }
    .grid__cell--$(size)-2 { width: calc(2/$cols * 100%); }
    .grid__cell--$(size)-3 { width: calc(3/$cols * 100%); }
    .grid__cell--$(size)-4 { width: calc(4/$cols * 100%); }
    .grid__cell--$(size)-5 { width: calc(5/$cols * 100%); }
    .grid__cell--$(size)-6 { width: calc(6/$cols * 100%); }
    .grid__cell--$(size)-7 { width: calc(7/$cols * 100%); }
    .grid__cell--$(size)-8 { width: calc(8/$cols * 100%); }
    .grid__cell--$(size)-9 { width: calc(9/$cols * 100%); }
    .grid__cell--$(size)-10 { width: calc(10/$cols * 100%); }
    .grid__cell--$(size)-11 { width: calc(11/$cols * 100%); }
    .grid__cell--$(size)-12 { width: calc(12/$cols * 100%); }

}

@mixin grid xs;

@media (--grid-sm) {
    @mixin grid sm;
}

@media (--grid-md) {
    @mixin grid md;
}

@media (--grid-lg) {
    @mixin grid lg;
}

@media (width < 768px) {
    .grid {
        display: flex;
        flex-wrap: wrap;
        }
    .grid__cell {
        width: 100%;
        flex-shrink: 0;
        flex-grow: 0;
        }
    .grid--reverse {
        & > .grid__cell:first-child {
            order: 2;
            }
    }
    .grid__cell + .grid__cell {
        margin-top: 1.5rem;
        }

    .grid + .grid {
        margin-top: 1.5rem;
        }
}
