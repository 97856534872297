/** ------------------------------
    Page Hero
    ------------------------------ */
@media (width >= 768px) {
    .logo-holder {
        width: calc(100% + 60px);
        }
}

.pager-header {
    width: 100%;
    margin-bottom: 2rem;
    }

.brand-home {
    & .pager-header {
        margin-bottom: 4rem;
    }
}

@media (width < 768px) {
    .pager-header {
        /*width: 100%;
        max-width: 500px;*/
        margin-bottom: 1rem;

        height: 115px;
        width: auto;
        max-width: none;

        &.pager-header--large {
            max-width: 100%;
            height: auto;
            }
        }

    .brand-home {
        & .pager-header {
            margin-bottom: 3rem;
        }
    }
}
