/** ------------------------------
    Mixins
    ------------------------------ */

/*
    Only place global mixins here.
    Component-specific mixins such as `button` should be in
    the relevant component file.
 */

@define-mixin base-margin $scale: 1 {
    margin-top: 0;
    margin-bottom: calc(var(--base-rhythm) * $scale);
}

/* Grid mixins are used for layouts that a similar to grids, but not a lazy .grid component */
@define-mixin grid-container {
    margin-left: calc(var(--grid-gutter) / -2);
    margin-right: calc(var(--grid-gutter) / -2);
}

@define-mixin grid-item {
    padding-left: calc(var(--grid-gutter) / 2);
    padding-right: calc(var(--grid-gutter) / 2);
}

@define-mixin button-reset {
    appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
}

@define-mixin list-reset {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

@define-mixin btn-reset {
    appearance: none;
    border: 0;
    background: transparent;
}
