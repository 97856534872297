@custom-media --menu-collapsed (width < 1200px);
@custom-media --menu-expanded (width >= 1200px);

.brand-vision {
    & .btn {
        background-color: #ff550e;
    }

    & .quote,
    & .highlight {
        color: #ff550e;
    }
}

@media (--menu-expanded) {

    .brand-vision {

        & .site-header__menu li.current a,
        & .site-header__menu li.current a:before,
        & .site-header__menu li.current a:hover {
            background: #ff550e !important;
        }
    }

    a.brand-vision {
        &:hover,
        &:hover:before {
            background: #ff550e !important;
        }
    }
}


.brand-insights {
    & .btn {
        background-color: #e4813d;
    }

    & .quote,
    & .highlight {
        color: #e4813d;
    }
}

@media (--menu-expanded) {

    .brand-insights {
        & .site-header__menu li.current a,
        & .site-header__menu li.current a:before,
        & .site-header__menu li.current a:hover {
            background: #e4813d !important;
        }
    }

    a.brand-insights {
        &:hover,
        &:hover:before {
            background: #e4813d !important;
        }
    }
}


.brand-strategy {
    & .btn {
        background-color: #ff9900;
    }

    & .styled-list li::marker,
    & .quote,
    & .highlight {
        color: #ff9900;
    }
}

@media (--menu-expanded) {

    .brand-strategy {
        & .site-header__menu li.current a,
        & .site-header__menu li.current a:before,
        & .site-header__menu li.current a:hover {
            background: #ff9900 !important;
        }
    }

    a.brand-strategy {
        &:hover,
        &:hover:before {
            background: #ff9900 !important;
        }
    }
}

.brand-international {
    & .btn {
        background-color: #d6b85b;
    }

    & .quote,
    & .highlight {
        color: #d6b85b;
    }
}

@media (--menu-expanded) {

    .brand-international {
        & .site-header__menu li.current a,
        & .site-header__menu li.current a:before,
        & .site-header__menu li.current a:hover {
            background: #d6b85b !important;
        }
    }

    a.brand-international {
        &:hover,
        &:hover:before {
            background: #d6b85b !important;
        }
    }
}


.brand-digital {
    & .btn {
        background-color: #ecb04f;
    }

    & .quote,
    & .highlight {
        color: #ecb04f;
    }
}

@media (--menu-expanded) {

    .brand-digital {
        & .site-header__menu li.current a,
        & .site-header__menu li.current a:before,
        & .site-header__menu li.current a:hover {
            background: #ecb04f !important;
        }
    }

    a.brand-digital {
        &:hover,
        &:hover:before {
            background: #ecb04f !important;
        }
    }
}

.brand-sustainability {
    & .btn {
        background-color: #b59e74;
    }

    & .quote,
    & .highlight {
        color: #b59e74;
    }
}

@media (--menu-expanded) {

    .brand-sustainability {
        & .site-header__menu li.current a,
        & .site-header__menu li.current a:before,
        & .site-header__menu li.current a:hover {
            background: #b59e74 !important;
        }
    }

    a.brand-sustainability {
        &:hover,
        &:hover:before {
            background: #b59e74 !important;
        }
    }
}

.brand-toolkits {
    & .btn {
        background-color: #6ace7e;
    }

    & .quote,
    & .highlight {
        color: #6ace7e;
    }
}

@media (--menu-expanded) {

    .brand-toolkits {
        & .site-header__menu li.current a,
        & .site-header__menu li.current a:before,
        & .site-header__menu li.current a:hover {
            background: #6ace7e !important;
        }
    }

    a.brand-toolkits {
        &:hover,
        &:hover:before {
            background: #6ace7e !important;
        }
    }
}

.brand-best {
    & .btn {
        background-color: #6ac8ce;
    }

    & .quote,
    & .highlight {
        color: #6ac8ce;
    }
}

@media (--menu-expanded) {

    .brand-best {
        & .site-header__menu li.current a,
        & .site-header__menu li.current a:before,
        & .site-header__menu li.current a:hover {
            background: #6ac8ce !important;
        }
    }

    a.brand-best {
        &:hover,
        &:hover:before {
            background: #6ac8ce !important;
        }
    }
}
