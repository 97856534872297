.contact-holder {
    position: relative;
    }

.contact-block {
    margin-top: 2rem;
    border: 5px solid #000;
    }
    .contact-block__content {
        padding: 1.5rem 1.5rem 1rem;
        }

.contact-holder__chevron {
    position: absolute;
    bottom: 0;
    left: 10px;
    width: 424px;
    max-width: 100%;
    transform: translateY(100%);
    }

@media (width >= 1200px) {

    .contact-block {
        display: flex;
        }
        .contact-block__icon {
            width: 140px;
            flex-shrink: 0;
            flex-grow: 0;
            background: #000;
            display: flex;
            align-items: center;
            justify-content: center;

            & img {
                width: 80%;
                }
            }
        .contact-block__content {
            display: flex;
            flex-wrap: wrap;

            & h2 {
                flex-basis: 100%;
                }
            }
            .contact-block__copy {
                flex: 0 1 calc(100% - 310px);

                & p {
                    max-width: 600px;
                    }
                }
            .contact-block__contacts {
                flex: 0 0 300px;
                padding-left: 2rem;

                & .btn {
                    width: 100%;
                    }
                }

    }

@media (width < 1200px) {

    .contact-block__icon {
        display: none;
        }

    @media (width >= 768px) {

        .contact-block__contacts {

            & .btn {
                margin-top: 0;
                }

            }

        }

}

@media (width < 768px) {

    .contact-holder {

        & .btn {
            width: 100%;
            }

        }

}
