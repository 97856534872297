/** ------------------------------
    Base
    ------------------------------ */
@define-mixin form-control {
    display: block;
    width: 100%;
    margin: var(--form-control-margin) 0;
    padding: 0 var(--form-control-padding);
    height: var(--form-control-height);
    line-height: var(--form-control-height);
    border: var(--form-control-border-width) solid var(--form-control-default-border-color);
    border-radius: var(--form-control-border-radius);
    background-color: var(--form-control-default-bg);
    font-family: var(--btn-font-family);
    color: var(--form-control-default-color);
    appearance: none; /* Remove iOS border-radius */
}

.form,
.form__group {
    @mixin base-margin;
    }
    .form__label {
        display: inline-block;
        }
    .form__control {
        @mixin form-control;

        &:focus {
            outline: none;
            border-color: var(--form-control-default-border-color-active);
            }
        }

    /* Fix padding of multi-line fields */
    textarea.form__control,
    select.form__control[multiple] {
        padding: var(--form-control-padding);
        height: auto;
        line-height: var(--base-line-height);
        }

    /* Colour pickers don't need to be 100% */
    .form__control[type="color"] {
        width: calc(var(--form-control-height) + 1rem);
        }


/** ------------------------------
    Custom controls
    ------------------------------ */
.custom-select-control {
    margin: var(--form-control-margin) 0;
    border: var(--form-control-border-width) solid var(--form-control-default-border-color);
    border-radius: var(--form-control-border-radius);
    background-color: var(--form-control-default-bg);
    background-image: url('../../img/inline/select-arrow.svg');
    background-position: calc(100% - 15px) 50%;
    background-repeat: no-repeat;
    background-size: 8px auto;

    &:focus-within {
        border-color: var(--form-control-default-border-color-active);
        }

    & > select {
        @mixin form-control;
        margin: 0;
        border: 0;
        border-radius: 0;
        background-color: transparent;

        &:focus {
            outline: 0;
            }

        &::-ms-expand {
            display: none;
            }
        }
    }

.custom-file-control {
    margin: var(--form-control-margin) 0;
    }
    .custom-file-control__btn {
        position: relative;
        display: inline-block;

        & [type="file"] {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            }
        }
